import moment from "moment";
import { WEB_ACTION } from "./actions";
import { IData } from "./common";

const DATA_COUNT = 900;
const COLLECTION_NAME = "DIS1";

interface IInstantSentimentData {
  index: IData[];
  sentiment: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IInstantSentimentData = {
  sentiment: [],
  index: [],
  requested: false,
  loaded: false,
};

export function loadDailyInstantSentiment(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.DailyInstantSentiment.requested) return;
    dispatch({
      type: WEB_ACTION.DAILY_INSTANT_SENTIMENT,
      payload: { requested: true, loaded: false, index: [], sentiment: [] },
    });
    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT) //90
      .onSnapshot((querySnapshot: any) => {
        var sentimentData: Object[] = [];
        var indexData: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          const dateVal = moment
            .unix(doc.data().dateTime.seconds)
            .format("YYYY/MM/DD HH:mm");
          sentimentData.push({
            name: "sentiment",
            value: [dateVal, doc.data().sentiment],
            date: doc.data().dateTime.seconds,
          });
          indexData.push({
            name: "index",
            value: [dateVal, doc.data().index_value],
            date: doc.data().dateTime.seconds,
          });
        });
        indexData = indexData.reverse();
        sentimentData = sentimentData.reverse();
        dispatch({
          type: WEB_ACTION.DAILY_INSTANT_SENTIMENT,
          payload: {
            index: indexData,
            sentiment: sentimentData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function DailyInstantSentiment(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case WEB_ACTION.DAILY_INSTANT_SENTIMENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const dailyInstantSentimentSelector = (state: any) =>
  state.DailyInstantSentiment;
