import antdIT from 'antd/lib/locale-provider/tr_TR';
import trMessages from "../locales/tr_TR.json";

const trLang = {
  messages: {
    ...trMessages
  },
  antd: antdIT,
  locale: 'tr',
};
export default trLang;
