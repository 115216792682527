import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 1;
const COLLECTION_NAME = "NS_PORTFOLIO";

interface PortfolioData {
  close: number;
  perday: number;
}

interface IPortfolioData {
  portfolio: {
    xsen030: PortfolioData;
    xsen070: PortfolioData;
    xsen500: PortfolioData;
  };
  requested: boolean;
  loaded: boolean;
}

const initialState: IPortfolioData = {
  portfolio: {
    xsen030: { close: 0, perday: 0 },
    xsen070: { close: 0, perday: 0 },
    xsen500: { close: 0, perday: 0 },
  },
  requested: false,
  loaded: false,
};

export function loadPortfolio(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Portfolio.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.PORTFOLIO,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var portfolioData: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          const dateVal = moment
            .unix(doc.data().dateTime.seconds)
            .format("YYYY/MM/DD");
          portfolioData = doc.data();
        });

        dispatch({
          type: SENTIMENT_ACTION.PORTFOLIO,
          payload: {
            portfolio: portfolioData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Portfolio(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.PORTFOLIO:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const portfolioSelector = (state: any) => state.Portfolio;
