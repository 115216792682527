import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { auth } from "../../firebase";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://user-api-ti3ljgilca-nw.a.run.app",
    prepareHeaders: async (headers, {}) => {
      const token = await auth.currentUser?.getIdToken();
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    agreement: builder.mutation<void, void>({
      query: (body) => ({
        url: `/agreement`,
        method: "POST",
        body,
      }),
    }),
  }),
});
