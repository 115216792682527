import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";

const DATA_COUNT = 5;
const COLLECTION_NAME = "NS_DAILY_SUGGESTION";

interface IDailySuggestions {
  suggestions: {
    symbol: string;
    open: number;
    close: number;
    netPerDay: number;
  }[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IDailySuggestions = {
  suggestions: [],
  requested: false,
  loaded: false,
};

export function loadDailySuggestions(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.DailySuggestions.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.DAILY_SUGGESTIONS,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var suggestionData: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          const dateVal = moment
            .unix(doc.data().dateTime.seconds)
            .format("YYYY/MM/DD");
          suggestionData.push({
            name: "suggestions",
            open: doc.data().open,
            symbol: doc.data().symbol,
            netPerday: doc.data().netPerDay,
            close: doc.data().close,
            dateTime: doc.data().dateTime.toDate(),
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.DAILY_SUGGESTIONS,
          payload: {
            suggestions: suggestionData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function DailySuggestions(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.DAILY_SUGGESTIONS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const dailySuggestionsSelector = (state: any) => state.DailySuggestions;
