import moment from "moment";
import "moment-timezone";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 1;
const COLLECTION_NAME = "NS_BIST_PERF_MONTHLY";

interface IXUData {
  performance: {
    xu100: number;
    xsen030: number;
    xsen070: number;
    xsen500: number;
  };
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  performance: {} as any,
  requested: false,
  loaded: false,
};

export function loadBistPerformanceMonthly(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.BistPerformanceMonthly.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.BIST_PERFORMANCE_MONTHLY,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        let performanceData={};
        querySnapshot.forEach(function (doc: any) {
            performanceData=doc.data();
        });

        dispatch({
          type: SENTIMENT_ACTION.BIST_PERFORMANCE_MONTHLY,
          payload: {
            performance: performanceData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function BistPerformanceMonthly(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.BIST_PERFORMANCE_MONTHLY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const bistPerformanceMonthlySelector = (state: any) => state.BistPerformanceMonthly;
