import moment from 'moment';
import 'moment-timezone';
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 10;
const COLLECTION_NAME = "NS_BIST_TOP_30";

interface IXUData {
  bistTop30: { main: string[]; reserve: string[]; }[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  bistTop30: [],
  requested: false,
  loaded: false,
};

export function loadBistTop30(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.BistTop30.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.BIST_TOP_30,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        let bistTop30Data: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
          bistTop30Data.push({
            name: "bisttop30",
            main: doc.data().main ?? [],
            reserve: doc.data().reserve ?? [],
            date: moment(doc.data().dateTime.toDate()).format("DD.MM.YY HH:mm"),
            incoming: doc.data().incoming ?? "",
            outgoing: doc.data().outgoing ?? "",
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.BIST_TOP_30,
          payload: {
            bistTop30: bistTop30Data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function BistTop30(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.BIST_TOP_30:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const bistTop30Selector = (state: any) => state.BistTop30;
