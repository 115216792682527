import { WEB_ACTION } from "./actions";

const COLLECTION = "MSG";
const DATA_COUNT = 200;

export interface IMessage {
  id: any;
  text: string;
  date: any;
  link: any;
  imageLink: any;
  paid: boolean;
  imageUrl: any;
  color: any;
  color2?: any;
}
interface IMessageList {
    list: IMessage[];
  requested: boolean;
  loaded: boolean;
}
const initialState: IMessageList = {
  list: [],
  requested: false,
  loaded: false,
};

export function loadMessages(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Messages?.requested) return;

    dispatch({
      type: WEB_ACTION.MESSAGES,
      payload: { requested: true, loaded: false, data: [] },
    });
    firebase
      .firestore()
      .collection(COLLECTION)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var msgData: IMessage[] = [];
        querySnapshot.forEach(function (doc: any) {
          const data = doc.data();

          msgData.push({
            id: doc.id,
            text: data.text,
            date: data.dateTime,
            link: data.url,
            imageLink: data.imageLink,
            paid: data.paid,
            imageUrl: "",
            color: data.color,
            color2: data.color2,
          });
        });
        dispatch({
          type: WEB_ACTION.MESSAGES,
          payload: { requested: true, loaded: true, list: msgData },
        });
      });
  };
}

export default function Messages(state = null, action: any) {
  switch (action.type) {
    case WEB_ACTION.MESSAGES:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}

export const messagesSelector = (state: any) => state.Messages as IMessageList;
