import moment from 'moment';
import 'moment-timezone';
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 10;
const COLLECTION_NAME = "NS_BIST_TOP_70";

interface IXUData {
    bistTop70: { main: string[]; reserve: string[]; }[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  bistTop70: [],
  requested: false,
  loaded: false,
};

export function loadBistTop70(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.BistTop70.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.BIST_TOP_70,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        let bistTop70Data: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
        bistTop70Data.push({
            name: "bisttop70",
            main: doc.data().main ?? [],
            reserve: doc.data().reserve ?? [],
            date: moment(doc.data().dateTime.toDate()).format("DD.MM.YY HH:mm"),
            incoming: doc.data().incoming ?? "",
            outgoing: doc.data().outgoing ?? "",
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.BIST_TOP_70,
          payload: {
            bistTop70: bistTop70Data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function BistTop70(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.BIST_TOP_70:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const bistTop70Selector = (state: any) => state.BistTop70;
