import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import { storage } from "../../../firebase";

const DATA_COUNT = 10;
const COLLECTION_NAME = "NS_NOTIFICATIONS";

interface INewsData {
  notifications: {
    title: string;
    desc: string;
    link?: string;
  }[];
  requested: boolean;
  loaded: boolean;
}

const initialState: INewsData = {
  notifications: [],
  requested: false,
  loaded: false,
};

export const readNotification = (
  firebase: any,
  id: string,
  notificationId: string
) => {
    if(!id || !notificationId)
    return false;
  firebase
    .firestore()
    .collection(`${COLLECTION_NAME}/${id}/notifications`)
    .doc(notificationId)
    .set({ read: true }, { merge: true });
};

export function loadNotifications(firebase: any, id: string) {
  if (!id)
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Notifications.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.NOTIFICATIONS,
      payload: { requested: true, loaded: false, news: [] },
    });

    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}/${id}/notifications`)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var notificationsData: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          const dateVal = moment
            .unix(doc.data().dateTime.seconds)
            .format("YYYY/MM/DD");
          notificationsData.push({
            name: "notifications",
            title: doc.data().title,
            desc: doc.data().desc,
            id: doc.id,
            link: doc.data().link,
            read: doc.data().read,
            dateTime: doc.data().dateTime.toDate(),
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.NOTIFICATIONS,
          payload: {
            data: notificationsData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Notifications(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.NOTIFICATIONS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const notificationSelector = (state: any) => state.Notifications;
