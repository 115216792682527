import moment from "moment";
import { WEB_ACTION } from "./actions";
import { IData } from "./common";

const DATA_COUNT = 960;
const COLLECTION_NAME = "SENTI_ONE";

interface ISenti1Data {
  sentiment: IData[];
  bist100: IData[];
  bist30: IData[];
  viop30: IData[];
  requested: boolean;
  loaded: boolean;
  lastUpdate: number;
}

const initialState: ISenti1Data = {
  sentiment: [],
  bist100: [],
  bist30: [],
  viop30: [],
  requested: false,
  loaded: false,
  lastUpdate: 0
};

export function loadSenti1(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Senti1Snapshot.requested) return;
    if (state.Senti1Snapshot.lastUpdate > 0 && state.Senti1Snapshot.lastUpdate < new Date().getUTCHours()) return;

    dispatch({
      type: WEB_ACTION.SENTI_1_SNAPSHOT,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .get().then((querySnapshot: any) => {
        var sentimentData: Object[] = [];
        var bist100: Object[] = [];
        var bist30: Object[] = [];
        var viop30: Object[] = [];

        var today = new Date().getUTCDay();
        var hourUtc = new Date().getUTCHours();
        var hide = hourUtc >= 7 && hourUtc < 15 && !(today === 0 || today === 6);

        var lastDate = new Date(querySnapshot.docs[0].data().dateTime.seconds * 1000);
        var prev = querySnapshot.docs.filter((x: any) => new Date(x.data().dateTime.seconds * 1000).getDay() != lastDate.getDay())[0];
        var prevDate = new Date(prev.data().dateTime.seconds * 1000);

        querySnapshot.forEach(function (doc: any) {
          const docDate = new Date(doc.data().dateTime.seconds * 1000);

          if (hide && docDate.getDay() != prevDate.getDay())
            return;

          if (!hide && docDate.getDay() != lastDate.getDay())
            return;

          if (state.oneMinPeriod !== 1) {
            const dateCheck = new Date(doc.data().dateTime.seconds * 1000);
            const min = dateCheck.getMinutes();
            if (state.oneMinPeriod === 5) {
              if (min % 5 !== 0) return;
            } else if (state.oneMinPeriod === 10) {
              if (min % 10 !== 0) return;
            }
          }
          const dateVal = moment
            .unix(doc.data().dateTime.seconds)
            .format("YYYY/MM/DD HH:mm");
          sentimentData.push({
            name: "sentiment",
            value: [dateVal, doc.data().value],
            date: doc.data().dateTime.seconds,
          });
          bist100.push({
            name: "bist100",
            value: [dateVal, doc.data().bist100 ?? 0],
            date: doc.data().dateTime.seconds,
          });
          bist30.push({
            name: "bist30",
            value: [dateVal, doc.data().bist30 ?? 0],
            date: doc.data().dateTime.seconds,
          });
          viop30.push({
            name: "viop30",
            value: [dateVal, doc.data().viop30 ?? 0],
            date: doc.data().dateTime.seconds,
          });
        });
        sentimentData = sentimentData.reverse();
        bist100 = bist100.reverse();
        bist30 = bist30.reverse();
        viop30 = viop30.reverse();

        dispatch({
          type: WEB_ACTION.SENTI_1_SNAPSHOT,
          payload: {
            sentiment: sentimentData,
            bist100: bist100,
            bist30: bist30,
            viop30: viop30,
            loaded: true,
            requested: true,
            lastUpdate: new Date().getUTCHours()
          },
        });
      });
  };
}

export default function Senti1Snapshot(state = initialState, action: any) {
  switch (action.type) {
    case WEB_ACTION.SENTI_1_SNAPSHOT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const senti1Selector = (state: any) => state.Senti1Snapshot;
