import { WEB_ACTION } from "./actions";

const COLLECTION_NAME = "USER";
export const LOGIN_TOKEN_NAME = "zp_token";
enum PACKAGE_CODES {
  PREMIUM = "PRM",
  WELCOME = "WELCOME",
}

export type Purchase = {
  package_code: string;
  package_end: {
    seconds: number;
  };
};

export type UserData = {
  purchases: Purchase[];
};

export interface IUser {
  userId: string;
  name: string;
  token: string;
  premium: boolean;
  welcome:boolean;
  loaded: boolean;
}

export function userHasPackage(data: UserData, packageName: string) {
  var exists = false;
  if (data && data.purchases) {
    for (var i in data.purchases) {
      if (data.purchases[i].package_code === packageName) {
        if (data.purchases[i].package_end.seconds) {
          if (
            data.purchases[i].package_end.seconds >
            new Date().getTime() / 1000
          ) {
            exists = true;
            break;
          }
        }
      }
    }
  }
  return exists;
}

export function loadUser(firebase: any, user: any) {
  return async function thun(dispatch: any, getState: any) {
    if (user == null) return;
    dispatch({
      type: WEB_ACTION.USER,
      payload: { requested: true, loaded: false, user: {} },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .doc(user.uid)
      .onSnapshot(async (querySnapshot: any) => {
        
        //Enfore single login
/*         const token = localStorage.getItem(LOGIN_TOKEN_NAME);
        if (token == null) {
          await firebase.auth().signOut();
          return;
        }
        var data = querySnapshot.data();
        if (data && data.web_token && token) {
          if (data.web_token != token) {
            await firebase.auth().signOut();
            return;
          }
        } */
        var data = querySnapshot.data();

       
        dispatch({
          type: WEB_ACTION.USER,
          payload: {
            user: querySnapshot.data(),
            premium: userHasPackage(data, PACKAGE_CODES.PREMIUM),
            welcome: userHasPackage(data, PACKAGE_CODES.WELCOME),
            requested: true,
            loaded: true,
          },
        });
      });
  };
}

export default function WebUser(state = null, action: any) {
  switch (action.type) {
    case WEB_ACTION.USER:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}

export const userSelector = (state: any) => state.WebUser;

